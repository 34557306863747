import React from 'react'
import { kebabCase } from 'lodash'
import { Link, graphql } from 'gatsby'
import { IconContext } from 'react-icons'
import { FiTag } from 'react-icons/fi'

// component
import DefaultLayout from '../../components/Layout/default'
import SEO from '../../components/SEO'

// type
import { TagsQuery } from '../../../types/graphql-types'
interface TagsPageProps {
  data: TagsQuery
}

const TagsPage: React.FC<TagsPageProps> = ({ data }) => {
  return (
    <DefaultLayout title="すべてのタグ">
      <section className="section">
        <SEO
          title="すべてのタグ"
          slug="/tags/"
          keywords={[
            '経済',
            'ブログ',
            'Notion',
            '投資',
            'プログラミング',
            'デザイン',
          ]}
        />

        <div className="container mx-auto">
          <div className=" pt-8 px-3 sm:py-10">
            <h1 className="text-2xl">すべてのタグ</h1>

            <ul className="flex flex-wrap mt-2">
              {data.allMarkdownRemark.group.map((tagData: any) => (
                <li
                  key={`${tagData.tag}tag`}
                  className="flex m-2 bg-backgroundColor1 rounded-lg ring ring-backgroundColor2 px-2 py-1"
                >
                  <Link to={`/tags/${kebabCase(tagData.tag)}/`}>
                    <span className="flex flex-row sm:text-lg">
                      <IconContext.Provider
                        value={{ className: 'text-md sm:text-xl mt-1' }}
                      >
                        <FiTag />
                      </IconContext.Provider>
                      &nbsp;{tagData.tag} ({tagData.totalCount})
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </DefaultLayout>
  )
}

export default TagsPage

export const tagPageQuery = graphql`
  query Tags {
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }
    }
  }
`
